/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
export default function BackGround(){
        return(
            <div className="footer-container">
                <h1 className="footer-title">Beach Resort | Hotel Room Book</h1>
                <p className="footer-copyright">© 2022 BeachResort - All Rights Copyright Reserved To <a href="https://ahmed-khalil.netlify.app/">Ahmed Khalil</a></p>
            </div>
    )
}

